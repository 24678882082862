import * as React from 'react';
import { Table, TableHeader, TableBody, TableVariant, TableProps } from '@patternfly/react-table';



const CompactTable: React.FunctionComponent<TableProps> = (props) => {
    return (
        <Table aria-label="compact-table" variant={TableVariant.compact} {...props}>
            <TableHeader />
            <TableBody />
        </Table>
    );
};

export default CompactTable;
