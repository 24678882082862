import { PaginationTitles } from '@patternfly/react-core';
import { IntlShape } from 'react-intl';
import messages_cs from './translations/cs';

export const locale = navigator.language.split(/[-_]/)[0];

const allMessages = {
    cs: messages_cs,
};

export const messages = allMessages[locale];

export const localizedPaginationTitles = (intl: IntlShape): PaginationTitles => {
    return {
        items: '',
        page: '',
        itemsPerPage: intl.formatMessage({ id: 'pagination.itemsPerPage', defaultMessage: 'Items per page'}),
        perPageSuffix: intl.formatMessage({ id: 'pagination.perPageSuffix', defaultMessage: 'per page'}),
        toFirstPage: intl.formatMessage({ id: 'pagination.toFirstPage', defaultMessage: 'Go to first page'}),
        toPreviousPage: intl.formatMessage({ id: 'pagination.toPreviousPage', defaultMessage: 'Go to previous page'}),
        toLastPage: intl.formatMessage({ id: 'pagination.toLastPage', defaultMessage: 'Go to last page'}),
        toNextPage: intl.formatMessage({ id: 'pagination.toNextPage', defaultMessage: 'Go to next page'}),
        optionsToggle: intl.formatMessage({ id: 'pagination.optionsToggle', defaultMessage: 'Items per page'}),
        currPage: intl.formatMessage({ id: 'pagination.currPage', defaultMessage: 'Current page'}),
        paginationTitle: intl.formatMessage({ id: 'pagination.paginationTitle', defaultMessage: 'Pagination'}),
        ofWord: intl.formatMessage({ id: 'pagination.ofWord', defaultMessage: 'of'}),
    };
};
