import * as React from 'react';
import { withRouter } from 'react-router';
import { Card, CardBody, CardTitle, Flex, FlexItem, Grid, GridItem, PageSection, Title } from '@patternfly/react-core';
import { getStationDetail } from '@app/api';
import GridCard from '@app/GridCard/GridCard';
import CompactTable from '@app/CompactTable/CompactTable';
import { Table, TableBody, TableVariant } from '@patternfly/react-table';
import { useIntl } from 'react-intl';

const StationDetail: React.FunctionComponent = (props) => {
    const [title, updateTitle] = React.useState('');
    const [topDays, updateTopDays] = React.useState([]);
    const [topHours, updateTopHours] = React.useState([]);
    const [details, updateDetails] = React.useState([]);

    const intl = useIntl();

    React.useEffect(() => {
        getStationDetail(props.computedMatch.params.id).then((data) => {
            const newTitle = data.name;
            updateTitle(newTitle);
            const days = [];
            data.top_days.forEach((item) => {
                days.push([intl.formatDate(item.day as string), item.rain as number] as never);
            });
            updateTopDays(days);
            const hours = [];
            data.top_hours.forEach((item) => {
                hours.push([intl.formatDate(item.day as string), item.hour as number, item.rain as number] as never);
            });
            updateTopHours(hours);
            const detailsIn = [
                [intl.formatMessage({ id: 'height', defaultMessage: 'Height' }) + ':', data.height + 'm'] as never,
                [intl.formatMessage({ id: 'basin', defaultMessage: 'Basin' }) + ':', data.basin] as never,
                [
                    intl.formatMessage({ id: 'basin.local', defaultMessage: 'Local basin' }) + ':',
                    data.partial_basin.split(' ').slice(1).join(' '),
                ] as never,
                [intl.formatMessage({ id: 'region', defaultMessage: 'Region' }) + ':', data.region_name] as never,
                [
                    intl.formatMessage({ id: 'local.municipality', defaultMessage: 'Local municipality' }) + ':',
                    data.local_municipality,
                ] as never,
            ];
            updateDetails(detailsIn);
        });
    }, [props.computedMatch.params.id]);

    return (
        <PageSection>
            <Grid hasGutter>
                <GridCard md={12}>
                    <Title headingLevel="h1">{title}</Title>
                </GridCard>
                <GridItem md={6}>
                    <Flex direction={{ default: 'column' }} spaceItems={{ default: 'spaceItemsMd' }}>
                        <FlexItem>
                            <Card>
                                <CardTitle>
                                    {intl.formatMessage({ id: 'details', defaultMessage: 'Details' })}
                                </CardTitle>
                                <CardBody>
                                    <Table
                                        aria-label="compact-table"
                                        variant={TableVariant.compact}
                                        rows={details}
                                        cells={['', '']}
                                    >
                                        <TableBody />
                                    </Table>
                                </CardBody>
                            </Card>
                        </FlexItem>
                        <FlexItem>
                            <Card>
                                <CardTitle>
                                    {intl.formatMessage({
                                        id: 'hourly.maximums',
                                        defaultMessage: 'Hourly Maximums (mm)',
                                    })}{' '}
                                </CardTitle>
                                <CardBody>
                                    <CompactTable
                                        rows={topHours}
                                        cells={[
                                            intl.formatMessage({ id: 'day', defaultMessage: 'Day' }),
                                            intl.formatMessage({ id: 'hour', defaultMessage: 'Hour' }),
                                            intl.formatMessage({ id: 'rain', defaultMessage: 'Rain' }),
                                        ]}
                                    />
                                </CardBody>
                            </Card>
                        </FlexItem>
                    </Flex>
                </GridItem>
                <GridCard title={intl.formatMessage({ id: 'daily.maximums', defaultMessage: 'Daily Maximums (mm)' })}>
                    <CompactTable
                        rows={topDays}
                        cells={[
                            intl.formatMessage({ id: 'day', defaultMessage: 'Day' }),
                            intl.formatMessage({ id: 'rain', defaultMessage: 'Rain' }),
                        ]}
                    />
                </GridCard>
            </Grid>
        </PageSection>
    );
};

export default withRouter(StationDetail);
