const BASE_URL =
    process.env.NODE_ENV === 'production' ? 'https://tkasparek-rain.duckdns.org:8443/' : 'http://localhost:8800/';

const queryParamsBuilder = (queryParams: { [key: string]: string | number | undefined }) => {
    let retval = '?';
    for (const [key, value] of Object.entries(queryParams)) {
        if (value !== undefined) {
            retval += key + '=' + value + '&';
        }
    }
    return retval.slice(0, retval.length - 1);
};

const apiCall = async (endpoint: string): Promise<any> => {
    const response = await fetch(BASE_URL + endpoint);
    return await response.json();
};

const getStationDetail = (stationId: string) => {
    return apiCall('stations/' + stationId);
};

const getStations = (page = 1, page_size = 10, filterName: string | undefined) => {
    const queryParams = queryParamsBuilder({
        page,
        page_size,
        'filter[name]': filterName,
    });
    return apiCall('stations' + queryParams);
};

const getOutliers = () => {
    return apiCall('outliers');
};

const getOverview = () => {
    return apiCall('overview');
};

const getOverviewWithDate = (date: string) => {
    return apiCall('overview/' + date);
};

const getHistogram = () => {
    return apiCall('histogram');
};

const getHistogramWithDate = (date: string) => {
    return apiCall('histogram/' + date);
};

const getRainDaily = (page = 1, page_size = 10) => {
    return apiCall(
        'rain/daily' +
            queryParamsBuilder({
                page,
                page_size,
            })
    );
};

const getRainDailyWithDate = (date: string, page = 1, page_size = 10) => {
    return apiCall(
        'rain/daily/' +
            date +
            queryParamsBuilder({
                page,
                page_size,
            })
    );
};

const getRainHourly = (page = 1, page_size = 10) => {
    return apiCall(
        'rain/hourly' +
            queryParamsBuilder({
                page,
                page_size,
            })
    );
};

const getRainHourlyWithDate = (date: string, page = 1, page_size = 10) => {
    return apiCall(
        'rain/hourly/' +
            date +
            queryParamsBuilder({
                page,
                page_size,
            })
    );
};

export {
    getStationDetail,
    getStations,
    getOutliers,
    getOverview,
    getOverviewWithDate,
    getHistogram,
    getHistogramWithDate,
    getRainDaily,
    getRainDailyWithDate,
    getRainHourly,
    getRainHourlyWithDate,
};
