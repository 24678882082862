import React from 'react';
import ReactDOM from 'react-dom';
import { App } from '@app/index';
import { IntlProvider } from 'react-intl';
import { locale, messages } from '@app/intlHelper';

if (process.env.NODE_ENV !== 'production') {
    const config = {
        rules: [
            {
                id: 'color-contrast',
                enabled: false,
            },
        ],
    };
    // eslint-disable-next-line @typescript-eslint/no-var-requires, no-undef
    const axe = require('react-axe');
    axe(React, ReactDOM, 1000, config);
}

ReactDOM.render(<IntlProvider locale={locale} messages={messages}><App /></IntlProvider>, document.getElementById('root') as HTMLElement);
