import * as React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { accessibleRouteChangeHandler } from '@app/utils/utils';
import Dashboard from '@app/Dashboard/Dashboard';
import Stations from '@app/Stations/Stations';
import StationDetail from '@app/StationDetail/StationDetail';
import { NotFound } from '@app/NotFound/NotFound';
import Outliers from '@app/Outliers/Outliers';
import { useDocumentTitle } from '@app/utils/useDocumentTitle';
import { LastLocationProvider, useLastLocation } from 'react-router-last-location';
import { createIntl } from 'react-intl';
import { locale, messages } from './intlHelper';

const intl = createIntl({ locale, messages });

let routeFocusTimer: number;

export interface IAppRoute {
    label?: string;
    /* eslint-disable @typescript-eslint/no-explicit-any */
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    /* eslint-enable @typescript-eslint/no-explicit-any */
    exact?: boolean;
    path: string;
    title: string;
    isAsync?: boolean;
}

const routes: IAppRoute[] = [
    {
        component: Dashboard,
        exact: true,
        label: intl.formatMessage({ id: 'dashboard', defaultMessage: 'Dashboard' }),
        path: '/',
        title: intl.formatMessage({ id: 'dashboard.title', defaultMessage: 'Rain | Dashboard' }),
    },
    {
        component: Stations,
        exact: true,
        label: intl.formatMessage({ id: 'stations', defaultMessage: 'Stations' }),
        path: '/stations',
        title: intl.formatMessage({ id: 'stations.title', defaultMessage: 'Rain | Stations' }),
    },
    {
        component: Outliers,
        exact: true,
        label: intl.formatMessage({ id: 'outliers', defaultMessage: 'Outliers' }),
        path: '/outliers',
        title: intl.formatMessage({ id: 'outliers.title', defaultMessage: 'Rain | Outliers' }),
    },
    {
        component: StationDetail,
        exact: true,
        path: '/stations/:id',
        title: intl.formatMessage({ id: 'station.details.title', defaultMessage: 'Rain | Station' }),
    },
];

// a custom hook for sending focus to the primary content container
// after a view has loaded so that subsequent press of tab key
// sends focus directly to relevant content
const useA11yRouteChange = (isAsync: boolean) => {
    const lastNavigation = useLastLocation();
    React.useEffect(() => {
        if (!isAsync && lastNavigation !== null) {
            routeFocusTimer = accessibleRouteChangeHandler();
        }
        return () => {
            window.clearTimeout(routeFocusTimer);
        };
    }, [isAsync, lastNavigation]);
};

const RouteWithTitleUpdates = ({ component: Component, isAsync = false, title, ...rest }: IAppRoute) => {
    useA11yRouteChange(isAsync);
    useDocumentTitle(title);

    function routeWithTitle(routeProps: RouteComponentProps) {
        return <Component {...rest} {...routeProps} />;
    }

    return <Route render={routeWithTitle} />;
};

const PageNotFound = ({ title }: { title: string }) => {
    useDocumentTitle(title);
    return <Route component={NotFound} />;
};

const AppRoutes = (): React.ReactElement => (
    <LastLocationProvider>
        <Switch>
            {routes.map(({ path, exact, component, title, isAsync }, idx) => (
                <RouteWithTitleUpdates
                    path={path}
                    exact={exact}
                    component={component}
                    key={idx}
                    title={title}
                    isAsync={isAsync}
                />
            ))}
            <PageNotFound title="404 Page Not Found" />
        </Switch>
    </LastLocationProvider>
);

export { AppRoutes, routes };
