const messages_cs = {
    'daily.maximums': 'Denní maxima (mm)',
    'station.name': 'Jméno stanice',
    rain: 'Srážky',
    'hourly.maximums': 'Maxima za hodinu (mm)',
    hour: 'Hodina',
    histogram: 'Histogram',
    dashboard: 'Přehled',
    'dashboard.title': 'Srážky | Přehled',
    stations: 'Stanice',
    'stations.title': 'Srážky | Stanice',
    outliers: 'Rekordy',
    'outliers.title': 'Srážky | Rekordy',
    height: 'Nadmořská výška',
    region: 'Kraj',
    day: 'Den',
    'overall.maximums': 'Celková maxmima (mm)',
    'overall.minimums': 'Celková minima (mm)',
    'pagination.items': '',
    'pagination.page': '',
    'pagination.itemsPerPage': 'Položek na stránce',
    'pagination.perPageSuffix': 'na stránku',
    'pagination.toFirstPage': 'Přejít na první stránku',
    'pagination.toPreviousPage': 'Přejít na předchozí stránku',
    'pagination.toLastPage': 'Přejít na poslední stránku',
    'pagination.toNextPage': 'Přejít na další stránku',
    'pagination.optionsToggle': 'Položek na stránce',
    'pagination.currPage': 'Aktuální stránka',
    'pagination.paginationTitle': 'Stránkování',
    'pagination.ofWord': 'z',
    details: 'Detaily',
    'station.details.title': 'Srážky | Detail stanice',
    basin: 'Dílčí povodí',
    'basin.local': 'Dílčí povodí III. řádu',
    'local.municipality': 'Obec s rozšířenou působností'
};

export default messages_cs;
