import * as React from 'react';
import { Card, CardBody, GridItem, CardTitle } from '@patternfly/react-core';

interface IGridCardProps extends React.HTMLProps<HTMLDivElement> {
    children?: React.ReactNode;
    md?: 1 | 2 | 3 | 4 | 5| 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
    sm?: 1 | 2 | 3 | 4 | 5| 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
    title?: string;
}

const defaultProps: IGridCardProps = {
    md: 6,
    sm: 12
}

const GridCard: React.FunctionComponent<IGridCardProps> = (props) => {
    return (
        <GridItem sm={props.sm} md={props.md}>
        <Card>
            {props.title && <CardTitle>{props.title}</CardTitle>}
            <CardBody>
                {props.children}
            </CardBody>
        </Card>
    </GridItem>
    );
}

GridCard.defaultProps = defaultProps;

export default GridCard;
