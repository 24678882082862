import * as React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { PageSection, Grid } from '@patternfly/react-core';
import { getOutliers } from '@app/api';
import GridCard from '@app/GridCard/GridCard';
import CompactTable from '@app/CompactTable/CompactTable';
import { useIntl } from 'react-intl';

const Outliers: React.FunctionComponent = () => {
    const [rowsDailyMax, updateDailyMax] = React.useState([]);
    const [rowsHourlyMax, updateHorlyMax] = React.useState([]);
    const [rowsTotalMax, updateTotalMax] = React.useState([]);
    const [rowsTotalMin, updateTotalMin] = React.useState([]);

    const intl = useIntl();

    React.useEffect(() => {
        getOutliers().then((data) => {
            const total = [];
            data.daily_maximums.forEach((item: { id: number; name: string; day: string; rain: number }) => {
                total.push([
                    <span key={item.id}>
                        <Link to={'/stations/' + item.id}>{item.name}</Link>
                    </span>,
                    intl.formatDate(item.day),
                    item.rain,
                ] as never);
            });
            updateDailyMax(total);
            const hourly = [];
            data.hourly_maximums.forEach(
                (item: { id: number; name: string; day: string; hour: number; rain: number }) => {
                    hourly.push([
                        <span key={item.id}>
                            <Link to={'/stations/' + item.id}>{item.name}</Link>
                        </span>,
                        intl.formatDate(item.day),
                        item.hour,
                        item.rain,
                    ] as never);
                }
            );
            updateHorlyMax(hourly);
            const totalMax = [];
            data.total_maximums.forEach((item: { id: number; name: string; rain: number }) => {
                totalMax.push([
                    <span key={item.id}>
                        <Link to={'/stations/' + item.id}>{item.name}</Link>
                    </span>,
                    item.rain,
                ] as never);
            });
            updateTotalMax(totalMax);
            const totalMin = [];
            data.total_minimums.forEach((item: { id: number; name: string; rain: number }) => {
                totalMin.push([
                    <span key={item.id}>
                        <Link to={'/stations/' + item.id}>{item.name}</Link>
                    </span>,
                    item.rain as number,
                ] as never);
            });
            updateTotalMin(totalMin);
        });
    }, []);

    return (
        <PageSection>
            <Grid hasGutter>
                <GridCard title={intl.formatMessage({ id: 'daily.maximums', defaultMessage: 'Daily maximums (mm)' })}>
                    <CompactTable
                        cells={[
                            intl.formatMessage({ id: 'station.name', defaultMessage: 'Name' }),
                            intl.formatMessage({ id: 'day', defaultMessage: 'Day' }),
                            intl.formatMessage({ id: 'rain', defaultMessage: 'Rain' }),
                        ]}
                        rows={rowsDailyMax}
                    />
                </GridCard>
                <GridCard title={intl.formatMessage({ id: 'hourly.maximums', defaultMessage: 'Hourly maximums (mm)' })}>
                    <CompactTable
                        cells={[
                            intl.formatMessage({ id: 'station.name', defaultMessage: 'Name' }),
                            intl.formatMessage({ id: 'day', defaultMessage: 'Day' }),
                            intl.formatMessage({ id: 'hour', defaultMessage: 'Hour' }),
                            intl.formatMessage({ id: 'rain', defaultMessage: 'Rain' }),
                        ]}
                        rows={rowsHourlyMax}
                    />
                </GridCard>
                <GridCard title={intl.formatMessage({ id: 'overall.maximums', defaultMessage: 'Overall Maximums (mm)' })}>
                    <CompactTable
                        cells={[
                            intl.formatMessage({ id: 'station.name', defaultMessage: 'Name' }),
                            intl.formatMessage({ id: 'rain', defaultMessage: 'Rain' }),
                        ]}
                        rows={rowsTotalMax}
                    />
                </GridCard>
                <GridCard title={intl.formatMessage({ id: 'overall.minimums', defaultMessage: 'Overall minimums (mm)' })}>
                    <CompactTable
                        cells={[
                            intl.formatMessage({ id: 'station.name', defaultMessage: 'Name' }),
                            intl.formatMessage({ id: 'rain', defaultMessage: 'Rain' }),
                        ]}
                        rows={rowsTotalMin}
                    />
                </GridCard>
            </Grid>
        </PageSection>
    );
};

export default withRouter(Outliers);
