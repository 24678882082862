import * as React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
    PageSection,
    Grid,
    InputGroup,
    InputGroupText,
    TextInput,
    Pagination,
    PaginationVariant,
} from '@patternfly/react-core';
import { CalendarAltIcon } from '@patternfly/react-icons';
import {
    getHistogram,
    getHistogramWithDate,
    getRainDaily,
    getRainHourly,
    getRainDailyWithDate,
    getRainHourlyWithDate,
} from '@app/api';
import GridCard from '@app/GridCard/GridCard';
import CompactTable from '@app/CompactTable/CompactTable';
import SimpleGraph from '@app/SimpleGraph/SimpleGraph';
import moment from 'moment';
import CSS from 'csstype';
import { useIntl } from 'react-intl';
import { localizedPaginationTitles } from '@app/intlHelper';

const style: CSS.Properties = {
    marginBottom: '40px',
    marginLeft: 'auto',
    width: '200px',
};

const Dashboard: React.FunctionComponent = () => {
    const [rowsTotal, updateRowsTotal] = React.useState([]);
    const [rowsHourly, updateRowsHourly] = React.useState([]);
    const [histogram, updateHistogram] = React.useState([]);
    const [dailyPage, updateDailyPage] = React.useState(1);
    const [dailyPerPage, updateDailyPerPage] = React.useState(10);
    const [dailyCount, updateDailyCount] = React.useState(0);
    const [hourlyPage, updateHourlyPage] = React.useState(1);
    const [hourlyPerPage, updateHourlyPerPage] = React.useState(10);
    const [hourlyCount, updateHourlyCount] = React.useState(0);
    const [date, updateDate] = React.useState(moment().subtract(1, 'day').format('YYYY-MM-DD'));

    const maxDate = moment().subtract(1, 'day').format('YYYY-MM-DD');

    const intl = useIntl();

    React.useEffect(() => {
        getHistogram().then((histogram) => {
            updateHistogram([
                { name: '0 mm', x: '0', y: histogram.data[0] } as never,
                { name: '0 mm < rain <= 10 mm', x: '0-10', y: histogram.data[10] } as never,
                { name: '10 mm < rain <= 25 mm', x: '10-25', y: histogram.data[25] } as never,
                { name: '25 mm < rain <= 50 mm', x: '25-50', y: histogram.data[50] } as never,
                { name: '50 mm < rain <= 100 mm', x: '50-100', y: histogram.data[100] } as never,
                { name: '100 mm < rain', x: '100+', y: histogram.data[1000] } as never,
            ]);
        });
        getRainDaily(dailyPage, dailyPerPage).then((daily) => {
            const tmp = [];
            daily.data.forEach((item: { id: number; name: string; rain: number }) => {
                tmp.push([
                    <span key={item.id}>
                        <Link to={'/stations/' + item.id}>{item.name}</Link>
                    </span>,
                    item.rain,
                ] as never);
            });
            updateRowsTotal(tmp);
            updateDailyCount(daily.meta.total_items);
        });
        getRainHourly(hourlyPage, hourlyPerPage).then((hourly) => {
            const tmp = [];
            hourly.data.forEach((item: { id: number; name: string; hour: number; rain: number }) => {
                tmp.push([
                    <span key={item.id}>
                        <Link to={'/stations/' + item.id}>{item.name}</Link>
                    </span>,
                    item.hour,
                    item.rain,
                ] as never);
            });
            updateRowsHourly(tmp);
            updateHourlyCount(hourly.meta.total_items);
        });
    }, []);

    React.useEffect(() => {
        getRainDailyWithDate(date, dailyPage, dailyPerPage).then((daily) => {
            const tmp = [];
            daily.data.forEach((item: { id: number; name: string; rain: number }) => {
                tmp.push([
                    <span key={item.id}>
                        <Link to={'/stations/' + item.id}>{item.name}</Link>
                    </span>,
                    item.rain,
                ] as never);
            });
            updateRowsTotal(tmp);
            updateDailyCount(daily.meta.total_items);
        });
    }, [dailyPage, dailyPerPage, date]);

    React.useEffect(() => {
        getRainHourlyWithDate(date, hourlyPage, hourlyPerPage).then((hourly) => {
            const tmp = [];
            hourly.data.forEach((item: { id: number; name: string; hour: number; rain: number }) => {
                tmp.push([
                    <span key={item.id}>
                        <Link to={'/stations/' + item.id}>{item.name}</Link>
                    </span>,
                    item.hour,
                    item.rain,
                ] as never);
            });
            updateRowsHourly(tmp);
            updateHourlyCount(hourly.meta.total_items);
        });
    }, [hourlyPage, hourlyPerPage, date]);

    const dateChanged = (value) => {
        updateDailyPage(1);
        updateHourlyPage(1);
        updateDate(value);
        getHistogramWithDate(value).then((histogram) => {
            updateHistogram([
                { name: '0 mm', x: '0', y: histogram.data[0] } as never,
                { name: '0 mm < rain <= 10 mm', x: '0-10', y: histogram.data[10] } as never,
                { name: '10 mm < rain <= 25 mm', x: '10-25', y: histogram.data[25] } as never,
                { name: '25 mm < rain <= 50 mm', x: '25-50', y: histogram.data[50] } as never,
                { name: '50 mm < rain <= 100 mm', x: '50-100', y: histogram.data[100] } as never,
                { name: '100 mm < rain', x: '100+', y: histogram.data[1000] } as never,
            ]);
        });
    };

    const setDailyPage = (_event, page) => {
        updateDailyPage(page);
    };

    const setDailyPerPage = (_event, pageSize) => {
        updateDailyPage(1);
        updateDailyPerPage(pageSize);
    };

    const setHourlyPage = (_event, page) => {
        updateHourlyPage(page);
    };

    const setHourlyPerPage = (_event, pageSize) => {
        updateHourlyPage(1);
        updateHourlyPerPage(pageSize);
    };

    const paginationTitles = localizedPaginationTitles(intl);

    return (
        <PageSection>
            <InputGroup style={style}>
                <InputGroupText component="label">
                    <CalendarAltIcon />
                </InputGroupText>
                <TextInput
                    name="date"
                    id="date"
                    type="date"
                    aria-label="datepicker"
                    min="2020-06-06"
                    max={maxDate}
                    onChange={dateChanged}
                />
            </InputGroup>
            <Grid hasGutter>
                <GridCard title={intl.formatMessage({ id: 'daily.maximums', defaultMessage: 'Daily maximums (mm)' })}>
                    <CompactTable
                        cells={[
                            intl.formatMessage({ id: 'station.name', defaultMessage: 'Name' }),
                            intl.formatMessage({ id: 'rain', defaultMessage: 'Rain' }),
                        ]}
                        rows={rowsTotal}
                    />
                    <Pagination
                        itemCount={dailyCount}
                        variant={PaginationVariant.bottom}
                        page={dailyPage}
                        perPage={dailyPerPage}
                        onSetPage={setDailyPage}
                        onPerPageSelect={setDailyPerPage}
                        titles={paginationTitles}
                    />
                </GridCard>
                <GridCard title={intl.formatMessage({ id: 'hourly.maximums', defaultMessage: 'Hourly maximums (mm)' })}>
                    <CompactTable
                        cells={[
                            intl.formatMessage({ id: 'station.name', defaultMessage: 'Name' }),
                            intl.formatMessage({ id: 'hour', defaultMessage: 'Hour' }),
                            intl.formatMessage({ id: 'rain', defaultMessage: 'Rain' }),
                        ]}
                        rows={rowsHourly}
                    />
                    <Pagination
                        itemCount={hourlyCount}
                        variant={PaginationVariant.bottom}
                        page={hourlyPage}
                        perPage={hourlyPerPage}
                        onSetPage={setHourlyPage}
                        onPerPageSelect={setHourlyPerPage}
                        titles={paginationTitles}
                    />
                </GridCard>
                <GridCard title={intl.formatMessage({id: 'histogram', defaultMessage: 'Histogram'})}>
                    <SimpleGraph histogram={histogram} />
                </GridCard>
            </Grid>
        </PageSection>
    );
};

export default withRouter(Dashboard);
