import * as React from 'react';
import {
    Chart,
    ChartAxis,
    ChartBar,
    ChartThemeColor,
    ChartVoronoiContainer,
} from '@patternfly/react-charts';

interface ISimpleGraphProps extends React.HTMLProps<HTMLDivElement> {
    children?: React.ReactNode;
    histogram?: any[];
}

const SimpleGraph: React.FunctionComponent<ISimpleGraphProps> = (props) => {
    return (
        <Chart
            containerComponent={
                <ChartVoronoiContainer labels={({ datum }) => `${datum.name}: ${datum.y}`} constrainToVisibleArea />
            }
            domainPadding={{ x: [30, 25] }}
            height={275}
            padding={{
                bottom: 50,
                left: 50,
                right: 50,
                top: 50,
            }}
            themeColor={ChartThemeColor.cyan}
            width={450}
        >
            <ChartAxis />
            <ChartAxis dependentAxis showGrid />
            <ChartBar data={props.histogram} />
        </Chart>
    );
};

export default SimpleGraph;
