import * as React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
    PageSection,
    Card,
    Pagination,
    PaginationVariant,
    Toolbar,
    ToolbarItem,
    ToolbarContent,
    Button,
    ButtonVariant,
    InputGroup,
    TextInput,
    debounce,
} from '@patternfly/react-core';
import { getStations } from '@app/api';
import CompactTable from '@app/CompactTable/CompactTable';
import { SearchIcon } from '@patternfly/react-icons';
import { useIntl } from 'react-intl';
import { localizedPaginationTitles } from '@app/intlHelper';

const Stations: React.FunctionComponent = () => {
    const [stations, updateStations] = React.useState([]);
    const [itemCount, updateItemCount] = React.useState(0);

    const [page, updatePage] = React.useState(1);
    const [perPage, updatePerPage] = React.useState(10);
    const [filterName, updateFilterName] = React.useState();

    const intl = useIntl();

    React.useEffect(() => {
        getStations(page, perPage, filterName).then((response) => {
            const toUpdate = [];
            response.data.forEach((item: { id: number; name: string; height: number; region_name: string }) => {
                toUpdate.push([
                    <span key={item.id}>
                        <Link to={'/stations/' + item.id} key={item.id}>
                            {item.name}
                        </Link>
                    </span>,
                    item.height,
                    item.region_name,
                ] as never);
            });
            updateStations(toUpdate);
            updateItemCount(response.meta.total_items);
        });
    }, [page, perPage, filterName]);

    const setPage = (_event, page) => {
        updatePage(page);
    };

    const setPerPage = (_event, pageSize) => {
        updatePage(1);
        updatePerPage(pageSize);
    };

    const updateFilters = (value) => {
        updatePage(1);
        updateFilterName(value);
    };

    const paginationTitles = localizedPaginationTitles(intl);

    return (
        <PageSection>
            <Card>
                <Toolbar id="toolbar">
                    <ToolbarContent>
                        <ToolbarItem>
                            <InputGroup>
                                <TextInput
                                    type="search"
                                    placeholder={intl.formatMessage({ id: 'station.name', defaultMessage: 'Name' })}
                                    onChange={debounce(updateFilters, 400)}
                                />
                                <Button variant={ButtonVariant.control} aria-label="search button for search input">
                                    <SearchIcon />
                                </Button>
                            </InputGroup>
                        </ToolbarItem>
                    </ToolbarContent>
                </Toolbar>
                <CompactTable
                    cells={[
                        intl.formatMessage({ id: 'station.name', defaultMessage: 'Name' }),
                        intl.formatMessage({ id: 'height', defaultMessage: 'Height' }),
                        intl.formatMessage({ id: 'region', defaultMessage: 'Region' }),
                    ]}
                    rows={stations}
                />
                <Pagination
                    itemCount={itemCount}
                    variant={PaginationVariant.bottom}
                    page={page}
                    perPage={perPage}
                    onSetPage={setPage}
                    onPerPageSelect={setPerPage}
                    titles={paginationTitles}
                />
            </Card>
        </PageSection>
    );
};

export default withRouter(Stations);
